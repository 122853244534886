
import { Title, Container, RedSection, Tr, Th, Td, Table, TextRanking } from './styles';


export function RankingPage() {

    return (
        <RedSection>
            <Container>
                <>
                    <Title>CONFIRA SUA POSIÇÃO NO RANKING</Title>
                    <div style={{ overflowY: 'auto' }}>
                        <Table>
                            <Tr>
                                <Th style={{ width: '10%' }}>POSIÇÃO</Th>
                                <Th>NOME</Th>
                                <Th>NOME DA LOJA</Th>
                                <Th style={{ width: '15%' }}>QUANTIDADE</Th>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>01</Td>
                                <Td>Alexandre Ranieri Peters</Td>
                                <Td>RELOJOARIA E OTICA UNIVERSAL LTDA</Td>
                                <Td style={{ width: '15%' }}>223</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>02</Td>
                                <Td>Luana Gongoleski</Td>
                                <Td>OTICA CRISTAL - MARIPA</Td>
                                <Td style={{ width: '15%' }}>186</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>03</Td>
                                <Td>Mariana Tavares Lima</Td>
                                <Td>M TAVARES LIMA OTICA EPP</Td>
                                <Td style={{ width: '15%' }}>140</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>04</Td>
                                <Td>Bruno Borges De Farias</Td>
                                <Td>BF OTICAS LTDA</Td>
                                <Td style={{ width: '15%' }}>129</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>05</Td>
                                <Td>Emilly Amaral Da Silva</Td>
                                <Td>OTICA PERFIL</Td>
                                <Td style={{ width: '15%' }}>123</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>06</Td>
                                <Td>Edgar François Arruda Junior</Td>
                                <Td>OTICAS VIP (R. FRANCISCO SANTOS)</Td>
                                <Td style={{ width: '15%' }}>97</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>07</Td>
                                <Td>Lívia Sala Arruda</Td>
                                <Td>OTICAS VIP (BOULEVARD SHOPPING VDC)</Td>
                                <Td style={{ width: '15%' }}>69</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>08</Td>
                                <Td>Mauricio Aires Rocha</Td>
                                <Td>CHILLI BEANS</Td>
                                <Td style={{ width: '15%' }}>68</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>09</Td>
                                <Td>Alícia Nessi</Td>
                                <Td>OJO BY MR RAY PARKSHOPPING</Td>
                                <Td style={{ width: '15%' }}>65</Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: '10%' }}>10</Td>
                                <Td>Alberto Baigorra Maizonave</Td>
                                <Td>OJO BY MR RAY (FLORIANOPOLIS)</Td>
                                <Td style={{ width: '15%' }}>60</Td>
                            </Tr>
                        </Table>
                        <TextRanking style={{ marginTop: '10px' }}>*Ranking (Categoria Vendedor) referente a apuração feita entre os dias 1 de outubro e 31 de outubro</TextRanking>
                    </div>
                </>
            </Container>
        </RedSection>
    )

}