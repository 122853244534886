/* eslint-disable react-hooks/exhaustive-deps */
// Módulos e Funções
import {
    Container, RedSection, Hero, FloatingRow, ButtonExplanation,
    RedTitle, BlackTitle, RelativeDiv, Counter, Number, Text, Span
} from './styles';
import { usePath } from '../../hooks/usePath';
import { useEffect, useState } from 'react';

// Imagens
import heroImg from '../../assets/images/hero.png'
import heroMobile from '../../assets/images/bannerMobile.png'
import premiosImg from '../../assets/images/premios.svg';
import uploadImg from '../../assets/images/upload.svg';
import regrasImg from '../../assets/images/regras.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { auth, db } from '../../config';

export function MainSection() {

    const { Path, handleNavigation } = usePath();
    const { width } = useWindowDimensions();

    function setPath(path: string) {
        handleNavigation(path);
    }

    const active = {
        transform: 'translateY(1.4rem)'
    };

    const [days, setDays] = useState('0');
    const [hours, setHours] = useState('0');
    const [minutes, setMinutes] = useState('0');

    useEffect(() => {
        (async () => {
            const target = new Date((await db.collection('config').doc('date').get()).data()?.date.seconds * 1000);

            const interval = setInterval(() => {
                const now = new Date();
                const difference = target.getTime() - now.getTime();

                const d = Math.floor(difference / (1000 * 60 * 60 * 24)).toString();
                const h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
                const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)).toString();

                setDays(d.padStart(2, '0'));
                setHours(h.padStart(2, '0'));
                setMinutes(m.padStart(2, '0'));
            }, 1000)

            return () => clearInterval(interval);
        })()
    }, []);

    return (
        <>
            <Container>
                <RelativeDiv>
                    <Hero src={width > 1024 ? heroImg : heroMobile} alt="Venda produtos RAY-BAN e ganhe prêmios incríveis" />
                    {width > 1024 ?
                        <Counter>
                            <Number>
                                0
                                <Text>DIAS</Text>
                            </Number>

                            <Number>
                                00
                                <Text>HORAS</Text>
                            </Number>

                            <Span>:</Span>

                            <Number>
                                00
                                <Text>MINUTOS</Text>
                            </Number>
                        </Counter>
                        : <></>}
                </RelativeDiv>
                <FloatingRow>
                    <ButtonExplanation
                        style={Path === '/' && width > 1024 ? active : {}}
                        onClick={() => { setPath('/') }}
                    >
                        <img src={regrasImg} alt="Regras" />
                        <BlackTitle>
                            COMO
                        </BlackTitle>
                        <RedTitle>PARTICIPAR</RedTitle>
                    </ButtonExplanation>

                    <ButtonExplanation
                        style={Path === '/premios' && width > 1024 ? active : {}}
                        onClick={() => { setPath('/premios') }}
                    >
                        <img src={premiosImg} alt="Prêmios" />
                        <BlackTitle>
                            SUPER
                        </BlackTitle>
                        <RedTitle>PRÊMIOS</RedTitle>
                    </ButtonExplanation>

                    <ButtonExplanation
                        style={Path === '/ranking' && width > 1024 ? active : {}}
                        onClick={() => { setPath('/ranking') }}
                    >
                        <img src={uploadImg} alt="Upload" />
                        <RedTitle>
                            RANKING
                        </RedTitle>
                    </ButtonExplanation>
                </FloatingRow>
            </Container>
            <RedSection>
            </RedSection>
        </>
    );
}
