import { createGlobalStyle, keyframes } from "styled-components";

import bebas from './BebasNeueBook.otf';

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateY(-0.5);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Bebas';
        src: url(${bebas});
    }

    :root { 
        --background: #FFFFFF;
        --red: #FF0D00;
        --gray: #A7A9AC;
        --black: #000000;
        --white: #FFFFFF;
        --text-title: #FFFFFF;
        --text-body: #000000;
        --text-special: #FF0D00
        --shape: white;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html { 
        @media (max-width: 1023px) { 
            font-size: 91.75%;
        }
        @media (max-width: 720) { 
            font-size: 87.5%;
        }
    }

    img {
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
    }

    body { 
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button, p, h1, h2, h3, h4, h5, select {
        font-family: 'Bebas', sans-serif;
        font-weight: 400;
    }

    button { 
        cursor: pointer;
    }

    [disabled] { 
        opacity: 0.6; 
        cursor: not-allowed; 
    }

    .react-modal-overlay {
        background: rgb(0, 0, 0, 0.5);
        position: fixed;
        z-index: 999;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-modal-content{
        animation: ${appear} 0.5s cubic-bezier(0, 0, 0.2, 1);
        width: 100%;
        max-width: 576px;
        background: var(--background);
        padding: 3rem;
        position: relative;
        border-radius: 0.25rem;
    }

    .react-modal-close {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        border: 0;
        background: transparent;
        transition: 0.2s;
        &:hover {
            filter: brightness(0.8);
        }
    }
`



