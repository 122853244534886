import { Container, Hitbox, RedSection, Relative } from './styles';

import regras1Img from '../../assets/images/regras.png';
import regras2Img from '../../assets/images/regras2.png';
import regras1ImgM from '../../assets/images/regras1m.png';
import regras2ImgM from '../../assets/images/regras2m.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';


export function RegrasPage() {

    const { width } = useWindowDimensions();

    return (
        <>
            <RedSection >
                <Container>
                    <Relative>
                        <img style={{ width: '80%', margin: '0 auto' }} src={width > 1024 ? regras1Img : regras1ImgM} alt="Regras" />
                    </Relative>

                    <Relative>
                        <img style={{ width: '80%', margin: '0 auto' }} src={width > 1024 ? regras2Img : regras2ImgM} alt="Regras" />
                        <Hitbox onClick={() => {
                            window.open('https://vantagens.essilorconecta.com.br/', '_blank');
                        }}></Hitbox>
                    </Relative>
                </Container>
            </RedSection>
        </>
    )

}