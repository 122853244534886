import styled from 'styled-components';
import footerImg from '../../assets/images/footer.png'

export const Container = styled.footer`
    background: var(--white);
    width: 100%;
    background-image: url(${footerImg});
    background-size: cover;
    background-position: center center;
`
export const Content = styled.div`
    max-width: 1220px;
    margin: 0 auto;

    padding: 2.5rem 0rem 4.5rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8rem;
    @media (max-width: 1023px) {
        flex-direction: column;
        padding-right: 0;
        justify-content: center;
    }
`

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
    @media (max-width: 1023px) {
        display: none;
    }
`

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1023px) {
        align-items: center;
        font-family: Arial;
    }
`

export const Copyright = styled.small`
    font-size: .8rem;
    color: var(--white);
    width: 65%;
    margin-top: 3rem;
    text-align: center;
    margin-right: 2rem;
    @media (max-width: 1023px) {
        width: 80%;
        margin-right: 0;
        align-items: center;
        font-size: .7rem;
    }
`

export const FooterLink = styled.button`
    text-decoration: none;
    color: var(--white);
    cursor: pointer;
    font-size: 1.5rem;
    padding: .5rem .5rem;
    transition: 0.3s;
    background: none;
    border: none;
    &:hover {
        transform: scale(1.06)
    }
`

export const Logo = styled.img`
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.03)
    }
`