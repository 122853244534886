import { Container, RedSection } from './styles';

import premios1Img from '../../assets/images/premios.png';
import premios2Img from '../../assets/images/premios2.png';
import premios1ImgM from '../../assets/images/premios1m.png';
import premios2ImgM from '../../assets/images/premios2m.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';



export function PremiosPage() {

    const { width } = useWindowDimensions();

    return (
        <RedSection>
            <Container>
                <img src={width > 1024 ? premios1Img : premios1ImgM} alt="Premios" />
                <img src={width > 1024 ? premios2Img : premios2ImgM} alt="Premios" />
            </Container>
        </RedSection>
    )

}