import { Container, RedTitle, FlexRow, Wrapper, Label, Input, ButtonCTA } from './styles';
import Modal from 'react-modal';

import { FormEvent, useState } from 'react';

import closeImg from '../../assets/images/close.svg';
import { useAuth } from '../../hooks/useAuth';
import Swal from 'sweetalert2';
import Notiflix from 'notiflix';
import { auth } from '../../config';

interface NewTransactionModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

export function LoginModal({ isOpen, onRequestClose }: NewTransactionModalProps) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { logIn } = useAuth();

    async function handleLogin(event: FormEvent) {
        event.preventDefault();

        if (!email) {
            presentSwal('Preencha seu e-mail para continuar');
            return;
        }

        if (!password) {
            presentSwal('Preencha sua senha para continuar');
            return;
        }

        Notiflix.Loading.hourglass();

        logIn(email.toLowerCase(), password).then(d => {
            onRequestClose();
            Notiflix.Loading.remove();
        }).catch(err => {
            Notiflix.Loading.remove();
            switch (err.code) {
                case 'auth/invalid-email':
                    presentSwal('Usuário não encontrado.');
                    return;
                case 'auth/wrong-password':
                    presentSwal('E-mail ou senha incorretos.');
                    return;
                default:
                    presentSwal('Ocorreu um erro ao realizar login, verifique seus dados e tente novamente.');
            }
        });
    }

    function presentSwal(text: string) {
        Swal.fire({
            title: 'Ops!',
            text: text,
            icon: 'warning',
            heightAuto: false
        });
    }

    async function handleResetPassword() {
        let { value: email } = await Swal.fire({
            title: 'Redefinição de Senha',
            input: 'text',
            inputLabel: 'Digite seu E-mail:',
            inputValue: '',
            showCancelButton: true,
            heightAuto: false,
            reverseButtons: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            text: 'Preencha o campo abaixo e clique em continuar para receber sua definição de senha por e-mail',
            inputValidator: (value) => {
                if (!value) {
                    return 'Digite seu e-mail para continuar'
                }
                return null;
            }
        });

        email = email.toLowerCase();
        Notiflix.Loading.hourglass();
        auth.sendPasswordResetEmail(email).then(d => {
            Swal.fire({
                title: 'Sucesso!',
                text: 'A redefinição de senha foi enviada para o seu e-mail!',
                icon: 'success',
                heightAuto: false
            });
        }).catch(err => {
            Swal.fire({
                title: 'Ops!',
                text: 'Ocorreu um erro ao enviar sua redefinição de senha, por favor verifique os dados e tente novamente',
                icon: 'error',
                heightAuto: false
            });
        }).finally(() => {
            Notiflix.Loading.remove();
        });
    }

    return (

        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button">
                <img src={closeImg} alt="Fechar Modal" onClick={onRequestClose}
                    className="react-modal-close" />
            </button>
            <Container onSubmit={handleLogin}>
                {window.location.href.includes('kgxf') ?
                    <FlexRow >
                        <RedTitle style={{ textAlign: 'center', width: '100%', padding: "5rem 0", fontSize: '3rem' }}>EM BREVE</RedTitle>
                    </FlexRow>
                    :
                    <>
                        <RedTitle>Login</RedTitle>
                        <FlexRow>
                            <Wrapper width="100%">
                                <Label>E-MAIL</Label>
                                <Input type="text" placeholder="E-MAIL" name="email"
                                    value={email} onChange={(event) => setEmail(event.target.value)}></Input>
                            </Wrapper>

                        </FlexRow>
                        <FlexRow>
                            <Wrapper width="100%" style={{ marginBottom: '.5rem' }}>
                                <Label>SENHA</Label>
                                <Input type="password" placeholder="SENHA" name="password"
                                    value={password} onChange={(event) => setPassword(event.target.value)}></Input>
                            </Wrapper>
                        </FlexRow>
                        <FlexRow>
                            <Wrapper width="100%" style={{ cursor: 'pointer' }} onClick={handleResetPassword}>
                                Esqueci minha senha
                            </Wrapper>
                        </FlexRow>

                        <ButtonCTA type="submit">
                            ENTRAR
                        </ButtonCTA>
                    </>
                }

            </Container>
        </Modal>
    );
}
