/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from './useAuth';



interface PathProviderProps {
    children: ReactNode;
}

interface PathContextData {
    Path: string;
    handleNavigation: (path: string) => void;
}

const PathContext = createContext<PathContextData>({} as PathContextData);


export function PathProvider({ children }: PathProviderProps) {

    const [Path, setPath] = useState<string>('');
    const history = useHistory();
    const { Auth } = useAuth();

    function handleNavigation(path: string) {
        if (Auth.name && path === '/cadastro') {
            history.push('/')
            return;
        }
        history.push(path);
        setPath(path);
    }

    useEffect(() => {
        setPath(history.location.pathname);
    }, []);


    return (
        <PathContext.Provider value={{ Path, handleNavigation }}>
            {children}
        </PathContext.Provider>
    );
}

export function usePath() {
    const context = useContext(PathContext);
    return context;
}