import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { RegrasPage } from './pages/RegrasPage/index';
import { RankingPage } from './pages/RankingPage/index';
import { PremiosPage } from './pages/PremiosPage/index';
import { MenuComponent } from "./components/Menu";
import { RegulamentoPage } from './pages/RegulamentoPage/index';

import { GlobalStyle } from "./styles/global";
import { AuthProvider } from './hooks/useAuth';
import { PathProvider } from './hooks/usePath';

import { BrowserRouter, Route } from 'react-router-dom';

import Modal from 'react-modal';
import Notiflix from "notiflix";
import MenuProvider from 'react-flexible-sliding-menu';

Notiflix.Loading.init({ svgColor: '#FF0D00' });
Modal.setAppElement('#root');

function App() {

  return (
    < BrowserRouter >
      <AuthProvider>
        <GlobalStyle />
        <MenuProvider MenuComponent={MenuComponent} width="100%">
          <PathProvider>
            <Header />
            <Route path="/" exact component={RegrasPage} />
            <Route path="/login" exact component={RegrasPage} />
            <Route path="/regras" exact component={RegrasPage} />
            <Route path="/premios" exact component={PremiosPage} />
            <Route path="/ranking" exact component={RankingPage} />
            <Route path="/regulamento" exact component={RegulamentoPage} />
            <Footer />
          </PathProvider>
        </MenuProvider>
      </AuthProvider>
    </BrowserRouter >
  );
}

export default App;
