import styled from 'styled-components';

export const Container = styled.main`
    margin: 0 auto;
    max-width: 1220px;
    position: relative;
    z-index: 2;
    padding: 1.5rem 0rem;
`

export const Hero = styled.img`
   width: 100%;
   @media (max-width: 1023px) {
       margin-top: 9.7rem;
   }
`

export const RedSection = styled.div`
    transform: translateY(-9.9rem);
    width: 100%;
    margin: 0 auto;
    background: var(--red);

    padding: 10rem 0rem;
    padding-bottom: 0rem;
    display: flex;
    align-items: center;
    justify-content: right;

    @media (max-width: 1023px) {
        padding-bottom: 1rem;
    }
`

export const FloatingRow = styled.div`
    position: absolute;
    z-index: 99 !important;
    bottom: -5rem;
    left: 0rem;
    padding: 0 8rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1023px) {
        bottom: -12rem;
        flex-direction: column;
        padding: 0 3rem;
        img {
            display: none;
        }
    }
`

export const ButtonExplanation = styled.button`
    border: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 2rem;
    width: 32%;
    background: var(--white);
    transition: 0.3s;
    text-decoration: none;
    img { 
        width: 14%;
        margin-left: -0.2rem
    }
    @media(min-width: 1024px) {
        &:hover {
            transform: translateY(0.5rem);
        }
    }
    @media (max-width: 1023px) {
        margin-top: 1rem;
        width: 100%;
        flex-direction: row;
        align-items: center;
        &:hover {
            transform: scale(1.03);
        }
    }
`

export const RedTitle = styled.p`
    color: var(--red);
    font-size: 2.4rem;
    margin-top: -5px;
    margin-bottom: 0;
    @media (max-width: 1023px) {
        font-size: 2.1rem;
        margin-top: 4px;
    }
    font-weight: 900;
`

export const BlackTitle = styled.p`
    color: var(--black);
    margin-top: 4px;
    font-size: 1.5rem;

    @media (max-width: 1023px) {
        font-size: 2.1rem;
        margin-right: .5rem;
    }
`
export const RelativeDiv = styled.div`
    position: relative;
`

export const Counter = styled.div`
    background: rgba(0,0,0,0.6);
    border: 1px solid var(--red);
    position: absolute;
    top: 20px;
    right: 50px;
    display:flex;
    flex-direction:row;
    padding:0px 0.8rem 4px 0.8rem;
`
export const Number = styled.p`
    color: var(--white);
    font-weight: 900;
    font-size: 2.5rem;
    justify-content: center;
    margin-top: -3px;

    &:first-child {
        margin-right: 10px;
    }

`
export const Text = styled.p`
    color: var(--gray);
    margin-top: -6px;
    font-weight: 100;
    text-align: center;
    font-size: 0.7rem;
`
export const Span = styled.p`
    color: var(--white);
    font-weight: 900;
    font-size: 2.7rem;
    margin:-0.5rem 0.2rem 0 0.2rem;
`