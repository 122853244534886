import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const Container = styled.main`
    *{animation: ${appear} 1s alternate};
    margin: 0 auto;
    max-width: 1220px;
    padding: 2rem 0rem;
    position: relative;
    z-index: 2;
    background: var(--white);
    margin-top: 6.4rem;
    width: 60.3rem;
    min-height: 10rem;
    text-align: center;
    img {
        margin-top: 1rem;
    }
    @media(max-width: 1023px) {
      width: 100%;
      padding: 1.5 2rem;
      margin-top: 13rem;
      margin-bottom: -0.1rem;
      img {
        max-width: 100vw;
      }
    }
`

export const RedSection = styled.div`
    width: 100%;
    margin: 0 auto;
    margin-top: -10.2rem;
    background: var(--red);
    padding-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: right;
    @media (max-width: 1023px) {
      padding-bottom: 0;
    }
`

export const ButtonCTA = styled.button`
    font-size: 1.5rem;
    color: var(--white);
    padding: 2rem 2.5rem;
    height: 3rem;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 10em;
    background: var(--red);
    text-decoration: none;
    img {
        width: 15px;
        margin: 0 10px 0 0;
    }
    &:hover {
       transform: scale(1.04);
    }
`

export const Relative = styled.div`
  position: relative;
`

export const Hitbox = styled.div`
    position: absolute;
    width: 30%;
    height: 100px;
    bottom: 35%;
    left: 37%;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      width: 65%;
      height: 90px;
      bottom: 18%;
      left: 18%;
    }
`