import styled from 'styled-components';


export const Container = styled.form`
    margin: 0 auto;
    max-width: 1120px;
    padding: 0rem 1rem;
`

export const TransactionTypeContainer = styled.div`
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
`

export const RedTitle = styled.h1`
    color: var(--red);
`

export const Input = styled.input`
    font-size: 1.2rem;
    padding: .5rem .8rem;
    border: 1px solid #A7A9AC;
    width: 100%;
    background: var(--white);
`

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const Label = styled.p`
    color: var(--black);
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
`

interface InputProps {
    width?: string;
}

export const Wrapper = styled.div<InputProps>`
    width: ${props => props.width ? props.width : '100%'};
    margin-bottom: 1.5rem;
`

export const ButtonCTA = styled.button`
    font-size: 1.5rem;
    color: var(--text-body);
    padding: 1.5rem 2rem;
    height: 3rem;
    display: flex;
    border: 1px solid var(--red);
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 7em;
    background: var(--white);
    text-decoration: none;
    img {
        width: 15px;
        margin: 0 10px 0 0;
    }
    &:hover {
       transform: scale(1.04);
    }
`