/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { auth, db } from '../config';

interface AuthProviderProps {
    children: ReactNode;
}

interface AuthContextData {
    Auth: User;
    logOut: () => void;
    logIn: (email: string, password: string) => Promise<any>;
}

interface User {
    name: string;
    email: string;
    cpf: string;
    cep: string;
    street: string;
    streetNumber: string;
    neighborhood: string;
    city: string;
    state: string;
    userType: string;
    cnpj: string;
    groupName: string;
    complement?: string;
    cluster: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);


export function AuthProvider({ children }: AuthProviderProps) {

    const [Auth, setAuth] = useState<User>({} as User);

    function logIn(email: string, password: string) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logOut() {
        auth.signOut();
    }

    useEffect(() => {
        auth.onAuthStateChanged((user: any) => {
            if (user) {
                db.collection('users').doc(user.email).get().then((d: any) => {
                    setAuth(d.data());
                });
            } else {
                setAuth({} as User);
            }
        });
    }, []);


    return (
        <AuthContext.Provider value={{ Auth, logOut, logIn }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    return context;
}