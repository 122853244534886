import { Container, Content, Item, Copyright} from './styles';

import logoImg from '../../assets/images/logo.png';
import closeImg from '../../assets/images/close2.svg';

import { useHistory } from 'react-router';
import { useContext } from 'react';
import { MenuContext } from 'react-flexible-sliding-menu';

export function MenuComponent() {

    const { toggleMenu } = useContext(MenuContext);

    const history = useHistory();

    function setPath(path: string) {
        window.scrollTo(0, 0);
        history.push(path);
        toggleMenu();
    }

    return (
        <Container>
            <Content>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
                    onClick={toggleMenu}>
                    <img src={closeImg} alt="Fechar Menu" />
                </div>
                <Item onClick={() => { setPath('/') }}>COMO PARTICIPAR</Item>
                <Item onClick={() => { setPath('/premios') }}>SUPER PRÊMIOS</Item>
                <Item onClick={() => { setPath('/rankling') }}>RANKING</Item>
               
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
                    <img src={logoImg} alt="Fechar Menu" />
                </div>

                <Copyright>
                    Copyright ©2020 Luxottica Group S.p.A. - Todos os direitos
                    reservados. Produtos sujeitos à disponibilidade de estoque.
                    Imagens ilustrativas, podem não refletir a realidade dos
                    produtos. SGH Brasil Comércio Óculos Ltda CNPJ: 13.257.648/0060-40 R.
                    Ministro Jesuíno Cardoso, 52 - 04544-050 - Vila Olímpia/SP
                </Copyright>
            </Content>
        </Container>
    )

}