import reg1 from '../../assets/reg1.pdf';
import { Container, RedSection, TitleBlack, TitleRed, FlexRow, Title } from './styles';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import useWindowDimensions from '../../hooks/useWindowDimensions';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export function RegulamentoPage() {

    const [numPages, setNumPages] = useState<any>(null);

    const { width } = useWindowDimensions();

    const [activeTab, setActiveTab] = useState('CARRO');

    function onDocumentLoadSuccess({ numPages }: any): void {
        setNumPages(numPages);
    }

    return (
        <RedSection>
            <Container>
                <TitleRed>
                    Regulamento
                </TitleRed>

                <Document
                    file={reg1}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading="Carregando Regulamento..."
                    renderMode="svg"
                >
                    {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map(page => <Page scale={width > 1023 ? 1.4 : .7} pageNumber={page} />)}
                </Document>
            </Container>
        </RedSection>
    )

}