import styled from 'styled-components';

export const Container = styled.div`
    background: var(--red);
    width: 100%;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
`
export const Content = styled.div`
    max-width: 100vw;
    margin: 0 auto;
    padding: 2rem;
    align-items: center;
    overflow: scroll;
`

export const Copyright = styled.p`
    font-size: .6rem;
    margin-top: 1rem;
    color: var(--white);
    width: 100%;
    text-align: center;
    font-family: Arial;
    text-align: center;
`

export const Logo = styled.img`
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.03)
    }
`

export const Item = styled.p`
    color: white;
    font-size: 2rem;
    margin-top: 1.2rem;
    margin-bottom: .5rem;
`

export const CTA = styled.button`
    font-size: 2rem;
    width: 100%;
    color: var(--white);
    background: var(--red);
    border: 1px solid var(--white);
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    margin-top: 2rem;
    img {
        width: 15px;
        margin-right: 10px;
    }
    &:hover {
        filter: brightness(0.96);
    }
`

export const ProfileRow = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-left: 1rem;
        font-size: 1.5rem;
        color: white;
    }
    
`