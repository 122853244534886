import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.main`
    *{animation: ${appear} 1s alternate};
    *{margin: 0 auto}
    margin: 0 auto;
    max-width: 1220px;
    padding: 2rem 3.5rem;
    position: relative;
    z-index: 2;
    background: var(--white);
    margin-top: 6.4rem;
    width: 60.3rem;
    text-align: center;
    img {
        margin-top: 1rem;
    }
    @media(max-width: 1023px) {
      max-width: 100vw;
      width: 100%;
      padding: 1rem 0rem;
      margin-top: 13rem;
    }
    `

export const RedSection = styled.div`
    transform: translateY(-10rem);
    width: 100%;
    margin: 0 auto;
    background: var(--red);
    padding-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: right;
    @media(max-width: 1023px) {
      max-width: 100vw;
    }
`

export const TitleRed = styled.h1`
  color: var(--red);
  font-size: 2.4rem;
  text-align: left;
  @media(max-width: 1023px) {
      padding: 0 2rem;
    }
`
interface TitleProps {
  active?: boolean | null;
}

export const Title = styled.div<TitleProps>`
    color: ${props => props.active ? 'var(--red)' : 'var(--text-body)'};
    border-bottom: ${props => props.active ? 'solid 2px var(--red)' : 'solid 2px transparent'};
    font-size: 2.2rem;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    margin-right: 2rem;
    @media(max-width: 1023px) {
     font-size: 1.9rem;
     margin: 0 auto;
    }
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  @media(max-width: 1023px) {
      width: 100%;
      &:first-child {
        padding-top: 2rem;
      }
    }
`

export const TitleBlack = styled.h1`
  color: var(--text-body);
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 1.5em;
  @media(max-width: 1023px) {
      padding: 0 2rem;
    }
`

export const RegulamentText = styled.p`
  font-size: .9rem;
  text-align: left;
  font-family: 'Arial';
`