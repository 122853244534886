import styled from 'styled-components';
export const Container = styled.header`
    background: var(--white);
`
export const Content = styled.div`
    max-width: 1220px;
    margin: 0 auto;

    padding: 2rem 0rem 0 0;
    display: flex;
    align-items: center;
    justify-content: right;
    position: relative;
`

export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 20%;
    img {
        margin-right: 1rem;
    }
    h3 {

    }
    h5 {
        color: #A7A9AC;
    }
`

export const Links = styled.div`
    @media (min-width: 1024px) {
        position: relative;
        margin-top: 1.5rem;
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media (max-width: 1023px) {
        display: none;
    }
`

export const FaleConosco = styled.a`
    position: absolute;
    top: -1.5rem;
    right: 1.8rem;
    text-decoration: none;
    color: var(--red);
`

export const CampanhaLogo = styled.img`
    @media (min-width: 1024px){
        position: absolute;
        top: 0;
        left: 8.3rem;
        z-index: 3;
        cursor: pointer;
        transition: 0.3s;
        width: 24%;
        &:hover {
            transform: scale(1.02);
        }
    }
    @media (max-width: 1023px){
        position: absolute;
        top: 6rem;
        left: 17.5vw;
        z-index: 3;
        cursor: pointer;
        transition: 0.3s;
        width: 65vw;
        &:hover {
            transform: scale(1.02);
        }
    }
`

export const CTA = styled.button`
        font-size: 1.1rem;
        color: var(--text-color);
        background: var(--white);
        border: 1px solid var(--red);
        padding: 0 2rem;
        height: 3rem;
        display: flex;
        align-items: center;
        transition: 0.2s;
        img {
            width: 15px;
            margin-right: 10px;
        }
        &:hover {
            filter: brightness(0.96);
        }
`

export const MenuContainer = styled.header`
    @media (min-width: 1024px){
        display: none;
    }
    @media (max-width: 1023px){
        margin-right: 2.87rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
            font-size: 1.5rem;
            margin-right: 1rem;
        }
        img {
            transition: 0.3s;
            &:hover {
                transform: scale(1.05);
            }
        }
    }
`

export const NavButton = styled.button`
        font-size: 1.25rem;
        color: var(--text-color);
        background: var(--white);
        height: 3rem;
        font-weight: 800;
        transition: 0.2s;
        border: none;
        padding: 0 1.4rem;
        text-decoration: none;
        display: flex;
        align-items: center;
        &:hover {
            filter: brightness(0.96);
        }
`