import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.main`
    *{animation: ${appear} .6s alternate};
    margin: 0 auto;
    max-width: 1220px;
    position: relative;
    z-index: 2;
    background: var(--white);
    margin-top: 6.4rem;
    width: 60.3rem;
    text-align: flex-start;
    padding: 2rem 3rem;
    img {
        margin-top: 1rem;
    }
    @media(max-width: 1023px) {
      width: 100%;
      padding: 2rem 0;
      margin-top: 13rem;
      margin-bottom: -0.1rem; 
    }
    `

export const RedSection = styled.div`
    width: 100%;
    margin: 0 auto;
    margin-top: -10.2rem;
    background: var(--red);
    padding-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: right;
    @media (max-width: 1023px) {
      padding-bottom: 0rem;
    }
`

export const Title = styled.p`
    color: var(--text-body);
    font-size: 2.2rem;
    font-weight: 900;
    transition: 0.3s;
    
    @media(max-width: 1023px) {
     font-size: 1.9rem;
      margin-left: 3rem;
    }
`

export const Table = styled.div`
    border: solid 2px rgb(112,112,112, 0.8);
    margin-top: 2rem;
    width: 100%;
    align-items: flex-start;
    display:flex;
    flex-direction: column;
    min-width: 600px;
    @media(max-width: 1023px) {
      margin-left: 3rem;
      margin-right: 3rem;
    }
`

export const Tr = styled.div`
  font-weight: 900;
  width: 100%;
  display:flex;
  flex-direction:row;
  justify-content: flex-start;

  &:first-child {
      border-bottom:  solid 2px rgb(112,112,112, 0.8);
    }
`
export const Th = styled.div`
  padding:1rem 2rem;
  width: 37.5%;
  font-weight: 900;
  display:flex;
  flex-direction:row;
  font-size: 1.5rem;
  text-align: center;
  display:flex;
  flex-direction:row;
  justify-content: center;
`

export const Td = styled.div`
  width: 37.5%;
  padding:1rem 2rem;
  color: rgb(112,112,112, 0.8);
  font-size: 1.3rem;
  font-weight: 900;
  border: none;
  text-align: center;
  display:flex;
  flex-direction:row;
    justify-content: center;
`

export const TextRanking = styled.p`
  margin-top: 10px;
  @media(max-width: 1023px) {
      margin-left: 3rem;
    }
`