import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

firebase.initializeApp({
    apiKey: "AIzaSyA5jO1GUaoN0BKHvDdm2LIvhDYOAXKOsrM",
    authDomain: "volume-maximo-ray-ban.firebaseapp.com",
    projectId: "volume-maximo-ray-ban",
    storageBucket: "volume-maximo-ray-ban.appspot.com",
    messagingSenderId: "1071425782665",
    appId: "1:1071425782665:web:cad5ce968a6dfa366a1758"
});

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export {
    db,
    auth,
    storage
}