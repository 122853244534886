import { Container, Content, Copyright, FlexColumn, FlexRow, FooterLink, Logo } from './styles'
import logoImg from '../../assets/images/logo.png'
import { usePath } from '../../hooks/usePath';

export function Footer() {

    const { handleNavigation } = usePath();

    function setPath(path: string) {
        window.scrollTo(0, 0);
        handleNavigation(path);
    }

    return (
        <Container>
            <Content>
                <Logo src={logoImg} alt="logo" onClick={() => { setPath("/") }} />
                <FlexColumn>
                    <FlexRow>
                        <FooterLink onClick={() => { setPath("/") }}>
                            COMO PARTICIPAR
                        </FooterLink>

                        <FooterLink onClick={() => { setPath("/premios") }}>
                            PRÊMIOS
                        </FooterLink>

                        <FooterLink onClick={() => { setPath("/ranking") }}>
                            RANKING
                        </FooterLink>

                        <FooterLink onClick={() => { setPath("/regulamento") }}>
                            REGULAMENTO
                        </FooterLink>
                        
                    </FlexRow>
                    <Copyright>
                        Copyright ©2020 Luxottica Group S.p.A. - Todos os direitos reservados.
                        Produtos sujeitos à disponibilidade de estoque. Imagens ilustrativas,
                        podem não refletir a realidade dos produtos. SGH Brasil Comércio Óculos
                        Ltda CNPJ: 13.257.648/0060-40 R. Ministro Jesuíno Cardoso,
                        52 - 04544-050 - Vila Olímpia/SP
                    </Copyright>
                </FlexColumn>
            </Content>
        </Container>
    )

}