/* eslint-disable react-hooks/exhaustive-deps */

import { Container, Content, NavButton, CampanhaLogo, Links, MenuContainer } from './styles';
import { MainSection } from '../MainSection';
import { useContext, useState, useEffect } from 'react';

import campanhaImg from '../../assets/images/campanha.svg';
import menuImg from '../../assets/images/menu.svg';

import Modal from 'react-modal';

import { MenuContext } from 'react-flexible-sliding-menu';
import { LoginModal } from '../LoginModal/index';
import { usePath } from '../../hooks/usePath';
import { useHistory } from 'react-router';

Modal.setAppElement('#root');
export function Header() {

    const { handleNavigation } = usePath();

    const [isNewLoginModalOpen, setisNewLoginModalOpen] = useState(false);

    const history = useHistory();

    const { toggleMenu } = useContext(MenuContext);


    useEffect(() => {
        if (history.location.pathname === '/login') {
            handleNavigation('/');
            handleOpenLoginModal();
        }
    }, [history.location.pathname]);

    function handleOpenLoginModal() {
        setisNewLoginModalOpen(true);
    }

    function handleCloseLoginModal() {
        setisNewLoginModalOpen(false);
    }

    function setPath(path: string) {
        handleNavigation(path);
    }

    return (
        <>
            <Container>
                <Content>
                    <MenuContainer onClick={toggleMenu}>
                        <p>MENU</p>
                        <img src={menuImg} alt="MENU" />
                    </MenuContainer>
                    <CampanhaLogo src={campanhaImg} onClick={() => { setPath('/') }} />
                    <Links>
                        <NavButton onClick={() => { setPath('/') }}>
                            COMO PARTICIPAR
                        </NavButton>

                        <NavButton onClick={() => { setPath('/premios') }}>
                            PRÊMIOS
                        </NavButton>

                        <NavButton onClick={() => { setPath('/ranking') }}>
                            RANKING
                        </NavButton>

                        <NavButton onClick={() => { setPath('/regulamento') }}>
                            REGULAMENTO
                        </NavButton>
                    </Links>
                </Content>
            </Container>

            <MainSection />

            <LoginModal onRequestClose={handleCloseLoginModal} isOpen={isNewLoginModalOpen} />
        </>
    );
}
